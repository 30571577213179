<template>
    <div class="wordspage">
        <div class="my-card wordspage-top van-hairline--surround">
            <div class="wordspage-title my-font-lg my-font-bold">
                {{ blog.title }}
            </div>
            <div class="wordspage-author my-font-md">
                <div class="wordspage-author-left">
                    <div>
                        <van-image
                            v-if="blogUser.userPictureurl"
                            round
                            width="2rem"
                            height="2rem"
                            fit="cover"
                            :src="blogUser.userPictureurl"
                            style="margin-right: 0.5rem"
                        ></van-image>
                    </div>
                    <span class="my-font-bold">{{ blog.author }}</span>
                </div>
                <span class="my-font-bold">{{ getTime(blog.time) }}</span>
            </div>
        </div>
        <div class="my-card wordspage-content my-font-md">
            <div v-html="blog.content"></div>
            <van-divider>全文完</van-divider>
        </div>
    </div>
</template>

<script>
import { getBlogById, getCommentById } from "@/api/common";
export default {
    components: {},
    data() {
        return {
            id: "",
            blog: {},
            blogUser: {},
            comments: [],
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.getId();
        this.getBlogById();
        this.getCommentById();
    },
    methods: {
        getId() {
            this.id = this.$route.params.id;
        },
        getBlogById() {
            const data = {
                blogId: this.id,
            };
            const roles = ["导师", "硕士", "博士"];
            getBlogById(data).then((res) => {
                this.blog.title = res.data.articleTitle;
                this.blog.content = res.data.articleDetails;
                this.blog.id = res.data.articleId;
                this.blog.author = res.data.userInfo.userNickname;
                this.blog.time = res.data.articleAddtime;
                this.blog.tags = res.data.blogtags;
                this.blog.files = res.data.articleallfile;
                this.blogUser = res.data.userInfo;
                this.blogUser.userRole = roles[this.blogUser.userRole - 1];
            });
        },
        getCommentById() {
            const data = {
                blogId: this.id,
            };
            getCommentById(data).then((res) => {
                // console.log(res.data);
                this.comments = res.data;
            });
        },
        getTime(time) {
            // 格式化时间
            return new Date(time).Format("yyyy-MM-dd");
        },
    },
};
</script>

<style scoped>
.wordspage-top {
}

.wordspage-title {
    margin-bottom: 1rem;

    font-size: 20px;
}

.wordspage-content {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.wordspage-author {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 0.5rem;
}

.wordspage-author-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/deep/ .wordspage-content h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/deep/ .wordspage-content h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/deep/ .wordspage-content h3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/deep/ .wordspage-content h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* /deep/ .wordspage-content code {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
} */

/deep/ .wordspage-content p {
    /* 段间距 */
    margin-bottom: 1rem;

    max-width: 1278px;
}

/deep/ .wordspage-content table {
    border-top: 1px solid #dcdee2;
    border-left: 1px solid #dcdee2;
}

/deep/ .wordspage-content table td,
table th {
    border-bottom: 1px solid #dcdee2;
    border-right: 1px solid #dcdee2;
    padding: 3px 5px;
}

/deep/ .wordspage-content table th {
    border-bottom: 2px solid #dcdee2;
    border-right: 1px solid #dcdee2;
    text-align: center;
}

/deep/ .wordspage-content blockquote {
    display: block;
    border-left: 8px solid #66b5f3;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
}

/deep/ .wordspage-content code {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background-color: #f1f1f1;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 0 3px;
}

/deep/ .wordspage-content pre code {
    display: block;
}

/deep/ .wordspage-content ul,
ol {
    margin: 10px 0 10px 20px;
}
</style>
